// Generated by Framer (915daba)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["qlBMBxhJm", "ZgplB38uO"];

const serializationHash = "framer-UNjyH"

const variantClassNames = {qlBMBxhJm: "framer-v-1qd80pl", ZgplB38uO: "framer-v-4fzt3a"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Closed: "qlBMBxhJm", Open: "ZgplB38uO"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, a5fwr9Mpw: click ?? props.a5fwr9Mpw, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "qlBMBxhJm"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, a5fwr9Mpw, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "qlBMBxhJm", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap18zzu0r = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (a5fwr9Mpw) {const res = await a5fwr9Mpw(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1qd80pl", className, classNames)} data-framer-name={"Closed"} data-highlight layoutDependency={layoutDependency} layoutId={"qlBMBxhJm"} onTap={onTap18zzu0r} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ZgplB38uO: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1jxc6iw"} layoutDependency={layoutDependency} layoutId={"Rk56ZTEje"} style={{backgroundColor: "var(--token-88d5059b-bc5d-4e0a-ad79-b21e9a2c4948, rgb(10, 10, 10))", rotate: 0}} variants={{ZgplB38uO: {rotate: 10}}}/><motion.div className={"framer-qxejnu"} layoutDependency={layoutDependency} layoutId={"UJhjTMNAx"} style={{backgroundColor: "var(--token-88d5059b-bc5d-4e0a-ad79-b21e9a2c4948, rgb(10, 10, 10))", rotate: 0}} variants={{ZgplB38uO: {rotate: -10}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UNjyH.framer-spcvwu, .framer-UNjyH .framer-spcvwu { display: block; }", ".framer-UNjyH.framer-1qd80pl { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-UNjyH .framer-1jxc6iw, .framer-UNjyH .framer-qxejnu { flex: none; height: 2px; overflow: hidden; position: relative; width: 59px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-UNjyH.framer-1qd80pl { gap: 0px; } .framer-UNjyH.framer-1qd80pl > * { margin: 0px; margin-bottom: calc(8px / 2); margin-top: calc(8px / 2); } .framer-UNjyH.framer-1qd80pl > :first-child { margin-top: 0px; } .framer-UNjyH.framer-1qd80pl > :last-child { margin-bottom: 0px; } }", ".framer-UNjyH.framer-v-4fzt3a.framer-1qd80pl { min-height: 12px; min-width: 59px; }", ".framer-UNjyH.framer-v-4fzt3a .framer-1jxc6iw, .framer-UNjyH.framer-v-4fzt3a .framer-qxejnu { left: 0px; position: absolute; right: 0px; top: calc(50.00000000000002% - 2px / 2); width: unset; z-index: 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 12
 * @framerIntrinsicWidth 59
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ZgplB38uO":{"layout":["auto","auto"]}}}
 * @framerVariables {"a5fwr9Mpw":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbE3WztOaD: React.ComponentType<Props> = withCSS(Component, css, "framer-UNjyH") as typeof Component;
export default FramerbE3WztOaD;

FramerbE3WztOaD.displayName = "Hamburger";

FramerbE3WztOaD.defaultProps = {height: 12, width: 59};

addPropertyControls(FramerbE3WztOaD, {variant: {options: ["qlBMBxhJm", "ZgplB38uO"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, a5fwr9Mpw: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerbE3WztOaD, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})